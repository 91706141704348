/** @jsx jsx */
import { jsx } from "theme-ui";
// import PropTypes from "prop-types";
import PeopleSelector from "gatsby-plugin-hfn-profile/components/PeopleSelector";
import { useCallback, useState } from "react";
import { colorShades } from "sites-common/utils/fabricStyles";
import { Stack, StackItem } from "office-ui-fabric-react";
// import { noop } from "sites-common/utils/lodash";
import renderPersonResult from "gatsby-plugin-hfn-profile/components/SrcmEntities/Render/renderPersonDetails";
import DefaultLayout from "../../../layouts/dlw-mobile";

// import {  CardsForm } from "@heartfulnessinstitute/react-hfn-forms/dist/ui/TableForm";

const PeopleList = () =>
  /* { aimsFields, formFields, onChange, values } */
  {
    const [people, setPeople] = useState({});
    const countPeople = Object.keys(people).length;

    const addPersons = useCallback(
      (pdict) => {
        setPeople((prev) => ({ ...prev, ...pdict }));
      },
      [setPeople]
    );

    return (
      <div sx={{ backgroundColor: colorShades.blueShade6 }}>
        {countPeople > 0 && (
          <div sx={{ mb: 2 }}>
            {Object.values(people).map((p, idx) => {
              return (
                <div sx={{ p: 2 }}>
                  {idx + 1}
                  <div>{renderPersonResult(p)}</div>
                  {/* {Object.entries(p).filter(([,c]) => c< 3).map(([k,v]) => <div>{k}: {JSON.stringify(v)}</div>)} */}
                </div>
              );
            })}

            {/* <CardsForm
            formFields={formFields}
            defaultValues={Object.values(people)}
            onSubmit={onChange}
          /> */}

            <div sx={{ my: 3 }}>
              <PeopleSelector
                btnType="action"
                btnProps={{
                  text: "Select More People",
                  iconProps: { iconName: "People" },
                }}
                onSelectionDone={addPersons}
                showMyself={false}
              />
            </div>
          </div>
        )}
        {!countPeople && (
          <Stack horizontal horizontalAlign="center" tokens={{ padding: 30 }}>
            <StackItem>
              <PeopleSelector onSelectionDone={addPersons} />
            </StackItem>
          </Stack>
        )}
      </div>
    );
  };

PeopleList.propTypes = {
  // aimsFields: PropTypes.arrayOf(PropTypes.string),
  // formFields: PropTypes.arrayOf(PropTypes.shape({})),
  // values: PropTypes.arrayOf(PropTypes.shape({})),
  // onChange: PropTypes.func,
};

PeopleList.defaultProps = {
  // values: [],
  // onChange: noop,
};

const KanhaVolunteering = () => {
  return (
    <DefaultLayout>
      <PeopleList
        aimsFields={["email", "mobile"]}
        formFields={[
          { name: "name", label: "Email" },
          { name: "role", label: "Role" },
          { name: "group", label: "Group" },
        ]}
      />
    </DefaultLayout>
  );
};

export default KanhaVolunteering;
